import React, { FunctionComponent } from 'react'
import { Helmet } from 'react-helmet'
import { graphql, useStaticQuery } from 'gatsby'
import { SiteMetadata } from '../utils/models'

interface SEOProps {
  title?: string
  lang?: string
  description?: string
  publishedAt?: string
  updatedAt?: string
  isArticle?: boolean
  tags?: string[]
  type?: 'WebSite' | 'Series' | 'Article'
  image?: string
  canonical?: string
}

export const SEO: FunctionComponent<SEOProps> = ({
  title,
  description,
  lang = 'pt-br',
  publishedAt,
  updatedAt,
  isArticle = false,
  tags = [],
  type = `Article`,
  image,
  canonical,
}) => {
  const { site } = useStaticQuery<SiteMetadata>(graphql`
    query {
      site {
        siteMetadata {
          title
          siteUrl
          description
          topics
          author {
            name
            description
            social {
              twitter
              facebook
            }
          }
        }
      }
    }
  `)
  const metadata = site.siteMetadata
  const siteTitle = title ? title : metadata.title
  const metaDescription = description
    ? description
    : metadata.description.replace('%TOPICS%', metadata.topics.join(', '))
  const metaImage = image
    ? `${metadata.siteUrl}/${image}`
    : `${metadata.siteUrl}/seo.png`

  return (
    <Helmet>
      <html lang={lang} />
      <title>{siteTitle}</title>
      <meta name="description" content={metaDescription} />
      <meta name="og:title" content={siteTitle} />
      <meta name="og:type" content={isArticle ? `article` : `website`} />
      <meta name="og:description" content={metaDescription} />
      <meta name="og:author" content={metadata.author.name} />
      <meta name="og:url" content={canonical} />
      <meta name="twitter:label1" content="Written by" />
      <meta name="twitter:data1" content={metadata.author.name} />
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:creator" content={metadata.author.name} />
      <meta name="twitter:title" content={siteTitle} />
      <meta name="twitter:description" content={metaDescription} />
      <meta
        name="twitter:keywords"
        content={metadata.topics.concat(tags).join(', ')}
      />
      <link rel="canonical" href={canonical} />

      {publishedAt && (
        <meta name="article:published_time" content={publishedAt} />
      )}
      {updatedAt && <meta name="article:modified_time" content={updatedAt} />}
      {metaImage && <meta name="og:image" content={metaImage} />}
      {metaImage && <meta name="twitter:image" content={metaImage} />}
      <script type={`application/ld+json`}>{`
        {
          "@context": "https://schema.org/",
          "@type": "${type}",
          "author": {
            "@type": "Person",
            "name": "${metadata.author.name}"
          },
          ${tags.length > 0 ? `"keywords": "${tags.join(`, `)}",` : ``}
          "headline": "${siteTitle}",
          "url": "${canonical}",
          ${publishedAt ? `"datePublished": "${publishedAt}",` : ``}
          ${updatedAt ? `"dateModified": "${updatedAt}",` : ``}
          ${
            metaImage
              ? `"image": {
            "@type": "ImageObject",
            "url": "${metaImage}",
            "width": "1000",
            "height": "520"
          },`
              : ``
          }
          "publisher": {
            "@type": "Organization",
            "name": "${metadata.title}"
          },
          "description": "${metaDescription}",
          "mainEntityOfPage": {
            "@type": "WebPage",
            "@id": "${metadata.siteUrl}"
          }
        }
      `}</script>
    </Helmet>
  )
}
